<template>
  <div
  v-if="isMobile" 
  class="line-container"
  >

  <div
        v-if="renderLine"
        class="line"
        :class="[checked ? 'line-selected' : '']"
      ></div>

    <div
    @click="onSelect"
    class="ua-container" :id="`ua-element-${this.index}`">
  <div class="circle center-check" :class="[checked ? 'selected' : '']">
        <svg
            class="check"
            fill="#ffffff"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="405.272px"
            height="405.272px"
            viewBox="0 0 405.272 405.272"
            style="enable-background:new 0 0 405.272 405.272;"
            xml:space="preserve"
          >
            <g>
              <path
                d="M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836
		c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064
		c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z"
              />
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
      </div>
    <div  class="pointer">
      <p class="heading">{{ uaRating.key }}</p>
      <p class="description">{{ uaRating.description }}</p>
    </div>
  </div>
  </div>
  <div v-else
  @click="onSelect"
  >
    <div class="circle-d-container">
      <div
        v-if="renderLineStart"
        class="d-line"
        :class="[checked ? 'line-selected' : '']"
      ></div>
      <div class="circle center-check" :class="[checked ? 'selected' : '']">
        <svg
            class="check"
            fill="#ffffff"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="405.272px"
            height="405.272px"
            viewBox="0 0 405.272 405.272"
            style="enable-background:new 0 0 405.272 405.272;"
            xml:space="preserve"
          >
            <g>
              <path
                d="M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836
		c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064
		c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z"
              />
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
      </div>

      <div
        v-if="renderLineEnd"
        class="d-line"
        :class="[checked ? 'line-selected' : '']"
      ></div>

    </div>

    <p class="heading mt-10"
    :style="headingStyle"
    >{{ uaRating.key }}</p>

  </div>
</template>

<script>
export default {
  props: ["uaRating", "index", "totalCount", "isMobile"],
  data() {
    return {
      renderLine: true,
      renderLineStart: true,
      renderLineEnd: true,
      timeout: null,
      checked: false,
      headingStyle: {},
    };
  },

  methods: {
    onSelect() {
      this.$emit("onSelect", this.index);
    },
  },
  mounted() {
    let textAlign = 'center';
    let marginStart = '0px';
    let width = '100%';
    let marginEnd = '0px';

    if (this.index === 0) {
      this.renderLine = false;
      this.renderLineStart = false;
    }
    if(this.index === this.totalCount -1){
      this.renderLineEnd = false;
    }
    if(!this.renderLineStart){
      textAlign = 'left';
      marginStart = '10px';
    }
    else if(!this.renderLineEnd){
      textAlign = 'left';
      marginStart = '30px';
    }
    let styleObj = {
      textAlign: textAlign,
      width: width,
      marginLeft: marginStart,
      marginRight: marginEnd
    };
    this.headingStyle = styleObj;

  },
  watch: {
    uaRating: {
      handler: function(uaRating, oldValue) {
        if (this.timeout !== null) {
          clearTimeout(this.timeout);
        }
        let checked = uaRating.checked;
        if (checked) {
          this.timeout = setTimeout(() => {
            this.checked = checked;
          }, this.index * 100);
        } else {
          this.checked = checked;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./UaRating.scss"
</style>
